<template>
  <div class="detail-til">
    <div>
      Statement Detail#{{ detailData.statement_id }}
      <icon-copy class="copy" @click="copyVal(detailData.statement_id)" />
      <a-divider direction="vertical" />
      <span class="time">{{ detailData.created_at }}</span>
    </div>
    <a-button type="primary" shape="round">
      <a style="text-decoration:none;" :href="detailData.label_file_name + '?' + Math.random()"
        :download="'statement_' + detailData.statement_id">
        <a-space>
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M5.41667 6.67453V0.458336H6.58333V6.67515L8.23356 5.02492L9.05852 5.84988L6.00031 8.90809L2.9421 5.84988L3.76706 5.02492L5.41667 6.67453ZM1.91667 10.375V9.20834H0.75V11.5417H11.25V9.20834H10.0833V10.375H1.91667Z"
              fill="white" />
          </svg>
          Download
        </a-space>
      </a>
    </a-button>
  </div>
  <div class="center-til">
    <div class="list-til">Transaction List</div>
    <a-statistic :value="startPrice" show-group-separator :precision="2">
      <template #prefix>
        $
      </template>
      <template #title>
        <a-space>
          Start Balance
          <span class="time">{{ detailData.start_at }}</span>
        </a-space>
      </template>
    </a-statistic>
  </div>
  <a-config-provider :locale="enUS">
    <a-table :columns="listColumns" :data="detailList" :pagination="false" style="margin:34px 24px 24px 24px;">
      <template #amount="{ record }">
        ${{ toThousands(record.amount) }}
      </template>
      <template #action="{ record }">
        <a-space>
          <a-tooltip content="Transaction">
            <a-button shape="circle" @click="goTransaction(record.type_num)">
              <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M2.34908 2.34809C2.90832 1.78885 3.59888 1.44375 4.32122 1.31142L4.14102 0.327789C3.22546 0.495514 2.34926 0.933697 1.64198 1.64098C-0.213015 3.49597 -0.213015 6.50351 1.64197 8.3585L0.788421 9.21205H3.20264V6.79784L2.34908 7.65139C0.884616 6.18692 0.884616 3.81255 2.34908 2.34809ZM9.21216 0.788314H6.79794V3.20253L7.65149 2.34897C9.11596 3.81344 9.11596 6.18781 7.65149 7.65228C7.09226 8.21151 6.4017 8.55662 5.67936 8.68894L5.85955 9.67257C6.77511 9.50485 7.65132 9.06667 8.3586 8.35938C10.2136 6.50439 10.2136 3.49686 8.3586 1.64187L9.21216 0.788314Z"
                  fill="#4E5969" />
              </svg>
            </a-button>
          </a-tooltip>
          <a-tooltip content="Download">
            <a-button shape="circle" v-if="record.type === 'Adjustment'">
              <a style="text-decoration:none;" :href="downloadAdjust + '?' + Math.random()"
                :download="'adjust_' + detailData.statement_id">
                <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M4.5 5.57859V0.250427H5.5V5.57912L6.91448 4.16464L7.62159 4.87175L5.00027 7.49307L2.37895 4.87175L3.08605 4.16464L4.5 5.57859ZM1.5 8.75043V7.75043H0.5V9.75043H9.5V7.75043H8.5V8.75043H1.5Z"
                    fill="#4E5969" />
                </svg>
              </a>
            </a-button>
          </a-tooltip>
          <a-tooltip content="Detail">
            <a-button shape="circle" @click="goAdjustbill" v-if="record.type === 'Adjustment'">
              <icon-right />
            </a-button>
          </a-tooltip>
        </a-space>
      </template>
    </a-table>
  </a-config-provider>
  <div class="bottom-cont">
    <a-statistic title="Downloads" :value="endPrice" show-group-separator :precision="2">
      <template #prefix>
        $
      </template>
      <template #title>
        <a-space>
          End Balance
          <span class="time">{{ detailData.end_at }}</span>
        </a-space>
      </template>
    </a-statistic>
  </div>
</template>

<script setup>
import { IconCopy, IconRight } from '@arco-design/web-vue/es/icon'
import enUS from '@arco-design/web-vue/es/locale/lang/en-us'
import { ref, reactive } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { Message, Notification } from '@arco-design/web-vue'
import { statementDetail, downloadAdjustbills } from '@/api/statement.js'
import { toThousands } from '@/utils/handleNumber.js'
// 使用loading
import { load } from '@/utils/loading.js'
const router = useRouter()
const route = useRoute()
const detaiId = route.query.detailId
const downloadAdjust = ref('')
// console.log(detaiId)
const listColumns = [
  {
    title: 'Transaction Type',
    dataIndex: 'type',
    sortable: { sortDirections: ['ascend', 'descend'] }
  },
  {
    title: 'Count',
    dataIndex: 'count',
    sortable: { sortDirections: ['ascend', 'descend'] }
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    slotName: 'amount',
    sortable: { sortDirections: ['ascend', 'descend'] }
  },
  {
    title: 'Action',
    dataIndex: 'action',
    slotName: 'action'
  }
]
const getdownloadAdjust = async () => {
  const msg = await downloadAdjustbills({
    adjust_date: detailData.value.statement_date
  })
  if (msg.code === 0) {
    downloadAdjust.value = msg.data.url
    // console.log(downloadAdjust.value)
  }
}
const detailData = ref({})
const startPrice = ref(0.00)
const endPrice = ref(0.00)
const detailList = ref([])
const getDetail = async () => {
  load.show('loading..')
  const msg = await statementDetail({
    id: detaiId
  })
  // console.log(msg)
  if (msg.code === 0) {
    load.hide()
    detailData.value = msg.data
    startPrice.value = Number(msg.data.start_balance)
    endPrice.value = Number(msg.data.end_balance)
    detailList.value = msg.data.statement_detail
    getdownloadAdjust()
  } else {
    load.hide()
    Notification.error({
      title: 'Error',
      content: msg.message
    })
  }
}
getDetail()
// 点击复制
const copyVal = (val) => {
  const input = document.createElement("input")
  document.body.appendChild(input)
  input.setAttribute("value", val)
  input.select()
  if (document.execCommand("copy")) {
    document.execCommand("copy")
    Message.success(val + '  ' + 'copied.')
  }
  document.body.removeChild(input)
}
const goAdjustbill = () => {
  router.push('/usercenter/adjustbill?start=' + detailData.value.start_at + '&end=' + detailData.value.statement_date)
}
const goTransaction = (num) => {
  // console.log(num)
  router.push('/usercenter/transaction?type=' + num + '&start=' + detailData.value.start_at + '&end=' + detailData.value.end_at)
}
</script>

<style lang="less" scoped>
.detail-til {
  padding: 18px 24px 18px 48px;
  font-weight: 500;
  font-size: 20px;
  color: var(--color-text-1);
  display: flex;
  justify-content: space-between;

  .copy {
    color: var(--color-text-2);
    font-size: 18px;
  }
}

.center-til {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 24px;

  .list-til {
    font-weight: 500;
    font-size: 16px;
    color: var(--color-text-1);
  }
}

.bottom-cont {
  padding-right: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.time {
  font-weight: 400;
  font-size: 14px;
  color: var(--color-text-3);
}

:deep(.arco-statistic-prefix) {
  font-size: 26px;
}
</style>
